import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxSelectModule } from "ngx-select-ex";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { RecoverComponent } from "./recover/recover.component";
import { TranslateModule } from "@ngx-translate/core";

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgxSelectModule,
    TranslateModule,
  ],
  declarations: [
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    RecoverComponent,
    ChangePasswordComponent,
  ],
  exports: [RouterModule],
})
export class AuthenticationModule {}
