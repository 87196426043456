import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { LoaderService } from "src/app/core/loading/loader.service";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { UsersService } from "../../users/users.service";
import { User } from "src/app/core/models/user";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  email: string = "";
  newPassword: string = "";
  confirmNewPassword: string = "";
  user: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notifications: NotificationsService,
    private loaderService: LoaderService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.loaderService.isLoading.next(false);
    this.changeForm = this.fb.group({
      email: [
        this.authenticationService.currentUser()?.email,
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      newPassword: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      confirmNewPassword: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  async submit() {
    let changeDTO = {
      email: this.authenticationService.currentUser()?.email,
      newPassword: this.formControls.newPassword.value,
      session: this.authenticationService.currentUser()?.session,
    };
    this.loaderService.isLoading.next(true);
    this.userService.updatePassword(changeDTO).subscribe(
      (userCognito) => {
        if (userCognito && userCognito.accessToken) {
          const user: User = new User();
          user.token = userCognito.accessToken;
          user.refreshToken = userCognito.refreshToken;
          let expiresAt = new Date(
            new Date().getTime() + userCognito.expiredIn * 1000
          );
          user.expiresTokenAt = expiresAt;
          user.secondsRefreshToken = userCognito.expiredIn;
          this.authenticationService.setCurrentUser(user);

          //FIND USER PROFILE
          this.userService.getProfile().subscribe((result) => {
            user.email = result.email;
            user.firstName = result.firstName;
            user.lastName = result.lastName;
            user.roles = [result.role];
            user.alertBank =
              result.bankInformation?.bankName !== "" ? false : true;
            this.authenticationService.setCurrentUser(user);
            if (result.bankInformation?.bankName === "") {
              this.router.navigate(["/users/bankinformation"]);
            } else {
              this.router.navigate(["/"]);
            }
          });
          this.loaderService.isLoading.next(false);
        }
      },
      (error) => {
        this.loaderService.isLoading.next(false);
        this.notifications.error("Error", "Password could not be updated");
      }
    );
  }

  public get formControls(): any {
    return this.changeForm.controls;
  }

  public noWhitespace(form: FormGroup) {
    let isWhitespace =
      form.controls.newPassword.value.trim().length === 0 &&
      form.controls.confirmNewPassword.value.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid;
  }

  public passwordsMatch(form: FormGroup): boolean {
    if (
      form.controls.newPassword.touched &&
      form.controls.confirmNewPassword.touched
    ) {
      if (form.value.newPassword === form.value.confirmNewPassword) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
