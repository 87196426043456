<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <p class="title">Sign in with your username and password</p>

      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="loginForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0">Username</label>

            <div class="input-group with-focus">
              <input
                class="form-control"
                autocomplete="off"
                formControlName="userName"
                required=""
                placeholder="Username"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                loginForm.controls['userName'].hasError('required') &&
                (loginForm.controls['userName'].dirty ||
                  loginForm.controls['userName'].touched)
              "
            >
              This field is required
            </div>
            <div class="text-danger ml-2 mt-2">{{ errors?.userName }}</div>
          </div>
          <div class="form-group">
            <label class="m-0">Password</label>

            <div class="input-group with-focus">
              <input
                class="form-control pin"
                id="exampleInputPassword1"
                type="password"
                name="password"
                formControlName="password"
                required=""
                placeholder="Password"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                loginForm.controls['password'].hasError('required') &&
                (loginForm.controls['password'].dirty ||
                  loginForm.controls['password'].touched)
              "
            >
              This field is required
            </div>
            <div class="text-danger ml-2 mt-2">{{ errors?.password }}</div>
            <div class="clearfix mt-2">
              <div class="">
                <a class="forgot-pin" [routerLink]="'/auth/reset'"
                  >Forgot your password?</a
                >
              </div>
            </div>
          </div>

          <button
            class="btn btn-block btn-primary mt-4 mb-4"
            type="submit"
            [disabled]="!loginForm.valid || !loginForm.dirty || !validUser()"
            (click)="submit()"
          >
            Sign In
          </button>
          <!--Will move below changes in next season 2024-->
          <!-- <hr /> -->
          <!-- <button class="btn btn-block btn-secondary mt-4" (click)="signUp()">
            Sign Up
          </button> -->
        </form>
      </div>
    </div>
  </div>
</div>
