import { AuthGuard } from "./core/guards/auth.guard";
import { SessionGuard } from "./core/guards/session.guard";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { JwtInterceptor, ErrorInterceptor } from "./helpers";
import { CoreModule } from "./core/core.module";
import { RoutesModule } from "./routes/routes.module";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { LoaderService } from "./core/loading/loader.service";
import { LoaderInterceptor } from "./core/loading/loader.interceptor";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { SentryErrorHandler } from "./core/handler/sentry-error-handler";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomTranslateHttpLoader } from "./helpers/customTranslateHttpLoader";

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      countDuplicates: true,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    SessionGuard,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
