export class User {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];

  code: string;
  token: string;
  refreshToken: string;
  expiresTokenAt: Date;
  secondsRefreshToken: number;
  alertBank: boolean;
  session: string;

  isInRole(role: string): boolean {
    return this.roles && this.roles.indexOf(role) !== -1;
  }
}
