import { Routes } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { SessionGuard } from "../core/guards/session.guard";
import { LayoutComponent } from "../layout/layout.component";
import { LoginComponent } from "./authentication/login/login.component";
import {ChangePasswordComponent } from "./authentication/change-password/change-password.component";
import { SignUpComponent } from "./authentication/sign-up/sign-up.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { RecoverComponent } from "./authentication/recover/recover.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: { roles: ["Farmer"] },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { roles: ["Farmer"] },
      },

      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["Farmer"] },
      },
    ],
  },
  {
    path: "auth",
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      { path: "reset", component: ResetPasswordComponent },
      { path: "sign-up", component: SignUpComponent },
      { path: "recover/:user", component: RecoverComponent },
      { 
        path: "change-password", 
        component: ChangePasswordComponent,
        canActivate: [SessionGuard],
      },
    ],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "error500",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  // Not found
  {
    path: "**",
    redirectTo: "home",
  },
];
