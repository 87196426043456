export const environment = {
  version: require("../../package.json").version,
  production: false,
  staging: true,
  development: false,
  apiUrl: "https://apifarmertest2.soliportal.com/api/v1",
  notificationsUrl: "",
  cognito:
    "https://solimarket-farmers-test.auth.us-east-2.amazoncognito.com/login?client_id=637ktahjer1rj28ci6buho75ah&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://farmertest2.soliportal.com/auth/login",

  cognitoUrl:
    "https://solimarket-farmers-test.auth.us-east-2.amazoncognito.com/",
  cognitoClientId: "637ktahjer1rj28ci6buho75ah",
  cognitoClientSecret: "",

  cognitoRedirectUrl: "https://farmertest2.soliportal.com/auth/login",
  cognitoScope: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  cognitoResponseType: "code",
};
