import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import moment from "moment";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { LookupService } from "src/app/core/services/lookup.service";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import * as lpn from "google-libphonenumber";
//import { GenericFunctionsService } from "src/app/core/services/generic-functions.service";
//import { SignUpCommand } from "src/app/dtos/signUpCommand";
//import { WalletService } from "src/app/core/services/wallet.service";
import { Router } from "@angular/router";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
//import { SignUpResponse } from "src/app/dtos/signUpResponse";
import { environment } from "src/environments/environment";
import { List } from "src/app/core/models/list";
//import { ParticipantSignUpService } from "src/app/core/services/participant-sign-up.service";
import { TranslateService } from "@ngx-translate/core";
import { StoreMarketDTO } from "src/app/dtos/storeMarketDTO";
import { StoreAddMarketDTO } from "src/app/dtos/storeAddMarketDTO";
import { StoreCreateDTO } from "src/app/dtos/storeCreateDTO";
import { parseDate } from "ngx-bootstrap/chronos";
import { StoreService } from "../store.service";

export function olderThan(years = 10): ValidatorFn {
  return function validate(control: AbstractControl) {
    const age = moment().diff(moment(control.value), "years");
    const isMajor = age >= years;
    if (!isMajor) {
      return {
        major: {
          major: years,
        },
      };
    }
    return null;
  };
}
@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  signUpForm: FormGroup;
  errors: { [key: string]: string[] };
  minDate = moment().subtract(122, "years").toDate();
  maxDate = new Date();

  // Phone
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneUtil: any = lpn.PhoneNumberUtil.getInstance();

  programItems: List[] = [{ Id: "48", Value: "Lousiana" }];

  //TYPEAHEAD STATE
  dataSourceState: Observable<LookupValueDTO[]>;
  dataSourceMailingState: Observable<LookupValueDTO[]>;
  typeaheadStateLoading = false;
  typeaheadStateNoResults = false;
  typeaheadMailingStateLoading = false;
  typeaheadMailingStateNoResults = false;
  //TYPEAHEAD REGION
  dataSourceRegion: Observable<LookupValueDTO[]>;
  typeaheadRegionLoading = false;
  typeaheadRegionNoResults = false;
  //TYPEAHEAD COUNTY
  dataSourceCounty: Observable<LookupValueDTO[]>;
  typeaheadCountyLoading = false;
  typeaheadCountyNoResults = false;
  disableGeofencing = false;

  dataSourceMarket: Observable<any>;
  typeaheadMarketLoading = false;
  typeaheadMarketNoResults = false;
  selectedMarkets: StoreAddMarketDTO[] = [];

  selectedMarketControl = true;
  marketSearchId: number = null;
  marketSearch: any;
  marketRelationType: string = null;
  relationTypeItems: List[] = [
    { Id: "PRIMARY", Value: "Primary" },
    { Id: "SECONDARY", Value: "Secondary" },
  ];

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private router: Router,
    private notifications: NotificationsService,
    public translate: TranslateService,
    private storeService: StoreService
  ) {
    translate.addLangs(["en", "es"]);
    translate.setDefaultLang("en");
  }

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      firstName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      middleName: ["", Validators.compose([Validators.maxLength(100)])],
      lastName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      stateName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      state: ["", Validators.compose([Validators.required])],
      street: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      city: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      zipCode: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern("^[0-9]{5}(?:-[0-9]{4})?$"),
        ]),
      ],
      county: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      countyName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      emailAddress: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
          ),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: [""],
      name: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      vendorCode: [
        "",
        Validators.compose([
          Validators.minLength(4),
          Validators.required,
          Validators.maxLength(4),
          Validators.min(1),
          Validators.max(9999),
        ]),
      ],
      products: [""],
      programCategory: ["", Validators.compose([Validators.required])],
    });

    this.signUpForm.patchValue({
      state: "LA",
      stateName: "Lousiana",
      programCategory: "48",
    });
    this.selectedMarkets = [];
    // this.dataSourceState = new Observable((observer: any) => {
    //   this.signUpForm.patchValue({ state: null });
    //   observer.next(this.signUpFormControls.stateName.value);
    // }).pipe(
    //   mergeMap((token: string) => this.lookupService.searchStates(token))
    // );

    this.dataSourceCounty = new Observable((observer: any) => {
      this.signUpForm.patchValue({ county: null });
      observer.next(this.signUpFormControls.countyName.value);
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchCounties(token))
    );
    this.dataSourceMarket = new Observable((observer: any) => {
      // Runs on every search
      this.selectedMarketControl = true;
      observer.next(this.marketSearch ? this.marketSearch : "");
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchMarkets(token))
    );

    if (environment.development) {
      this.signUpForm.patchValue({
        firstName: "Signup",
        middleName: "Dami",
        lastName: "Caba",
        stateName: "Lousiana",
        state: "LA",
        city: "Santa Fe",
        zipCode: "30000",
        county: "BE",
        countyName: "Bernalillo County",
        emailAddress:
          "qwerty" + Math.floor(1000 + Math.random() * 9000) + "@mail.com",
        phoneNumber: "1231231" + Math.floor(1000 + Math.random() * 9000),
      });
    }
  }

  public changeTypeaheadLoading(e: boolean, type: string): void {
    switch (type) {
      case "state":
        // this.typeaheadMailingStateLoading = e;
        break;
      case "county":
        this.typeaheadCountyLoading = e;
        break;
      case "market":
        this.typeaheadMarketLoading = e;
        break;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public changeTypeaheadNoResults(e: boolean, type: string): void {
    switch (type) {
      case "county":
        this.typeaheadCountyNoResults = e;
        break;
      case "state":
        //  this.typeaheadMailingStateNoResults = e;
        break;
      case "market":
        this.typeaheadMarketNoResults = e;
        break;
    }
  }

  public typeaheadOnSelect(e: TypeaheadMatch, type: string): void {
    switch (type) {
      case "county":
        this.signUpForm.patchValue({
          county: e.item.id,
          countyName: e.item.name,
        });
        break;
      case "state":
        // this.signUpForm.patchValue({
        //   state: e.item.id,
        //   stateName: e.item.name,
        // });
        break;
      case "market":
        this.marketSearch = e.item.description;
        this.marketSearchId = parseInt(e.item.id);
        break;
    }
  }

  submit() {
    this.selectedMarkets.forEach((element) => {
      delete element.marketName;
    });
    const store: StoreCreateDTO = {
      farmerProgramId: 48,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      emailAddress: this.f.emailAddress.value,
      phoneNumber: this.f.phoneNumber.value.internationalNumber,
      name: this.f.name.value,
      vendorCode: this.f.vendorCode.value,
      countyWhereSelling: this.f.county.value,
      mailingStreet: this.f.street.value,
      mailingCity: this.f.city.value,
      mailingState: this.f.state.value,
      mailingZipCode: this.f.zipCode.value,
      storeMarkets: this.selectedMarkets,
      products: this.f.products.value,
    };

    this.storeService.create(store).subscribe(
      (result) => {
        this.notifications.success("Create Farm", "The farm has been created");
        this.router.navigate([`/auth/login`]);
      },
      (error) => {
        this.errors = error?.errors;
      }
    );
  }

  public get signUpFormControls(): any {
    return this.signUpForm.controls;
  }

  formatPhone(event) {
    this.signUpForm.patchValue({
      phoneNumber: this.signUpFormControls.phoneNumber.value.nationalNumber,
    });
  }

  addRow(): any {
    this.selectedMarketControl = true;
    if (!this.marketSearch) {
      this.selectedMarketControl = false;
      return;
    }
    const dto: StoreAddMarketDTO = {
      marketId: this.marketSearchId,
      relationType: this.marketRelationType,
      marketName: this.marketSearch,
    };
    this.selectedMarkets.push(dto);
  }

  public get f(): any {
    return this.signUpForm.controls;
  }

  deactivateMarket(marketId: number): any {
    this.selectedMarkets = this.selectedMarkets.filter(function (item) {
      return item.marketId !== marketId;
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
