import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { User } from "src/app/core/models/user";
import { UsersService } from "../../users/users.service";
import { normalize } from "path";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  code: string;
  token: string;
  tokenType: string;
  expiresIn: number;
  loginForm: FormGroup;
  errors: { [key: string]: string[] };
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private notifications: NotificationsService,
    private fb: FormBuilder
  ) {
    this.authenticationService.clearStorage();
    // this.route.queryParams.subscribe((queryParams) => {
    //   if (queryParams.code) {
    //     this.code = queryParams.code;
    //   } else {
    //     this.authenticationService.loginCognito();
    //   }
    // });
    let params = new URLSearchParams(router.url.split("#")[1]);

    this.token = params.get("access_token");
    this.tokenType = params.get("token_type");
    this.expiresIn = Number(params.get("expires_in"));
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      userName: [null, Validators.compose([Validators.required])],
      password: [null, Validators.required],
    });
  }

  async submit() {
    let loginDTO = {
      // pan: !this.formControls.userName.value.includes("@")
      //   ? this.formControls.userName.value
      //   : null,
      email: this.formControls.userName.value.includes("@")
        ? this.formControls.userName.value
        : null,
      password: this.formControls.password.value,
    };

    this.userService.login(loginDTO).subscribe(
      (userCognito) => {
        if (userCognito && userCognito.accessToken) {
          const user: User = new User();
          user.token = userCognito.accessToken;
          user.refreshToken = userCognito.refreshToken;
          let expiresAt = new Date(
            new Date().getTime() + userCognito.expiredIn * 1000
          );
          user.expiresTokenAt = expiresAt;
          user.secondsRefreshToken = userCognito.expiredIn;
          this.authenticationService.setCurrentUser(user);

          //FIND USER PROFILE
          this.userService.getProfile().subscribe((result) => {
            //UPDATE USER LOGAL STORAGE
            user.email = result.email;
            user.firstName = result.firstName;
            user.lastName = result.lastName;
            user.roles = [result.role];
            user.alertBank =
              result.bankInformation?.bankName !== "" ? false : true;
            this.authenticationService.setCurrentUser(user);
            if (result.bankInformation?.bankName === "") {
              this.router.navigate(["/users/bankinformation"]);
            } else {
              this.router.navigate(["/"]);
            }
          });
        } else if (userCognito && userCognito.session) {
          const user: User = new User();
          user.email = userCognito.email;
          user.token = userCognito.accessToken;
          user.refreshToken = userCognito.refreshToken;
          let expiresAt = new Date(
            new Date().getTime() + userCognito.expiredIn * 1000
          );
          user.expiresTokenAt = expiresAt;
          user.secondsRefreshToken = userCognito.expiredIn;
          user.session = userCognito.session;
          this.authenticationService.setCurrentUser(user);
          this.router.navigate(["/auth/change-password"]);
        }
      },
      (error) => {
        if (error.errors) this.errors = error?.errors;
        else this.notifications.error("", error);
      }
    );
  }
  public get formControls(): any {
    return this.loginForm.controls;
  }
  validUser() {
    if (!this.formControls.userName.value.includes("@")) {
      if (this.formControls.userName.value.length !== 16) return false;
    }

    return true;
  }
  signUp() {
    this.router.navigate(["/auth/sign-up"]);
  }
}
