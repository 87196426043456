import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { List } from "../models/list";
import { StoreACHInformationDTO } from "src/app/dtos/storeACHInformationDTO";

export class BaseService {
  constructor(protected http: HttpClient) {}
  private baseUrl: string;
  private transformed: List[];

  protected setBaseUrl(url: string): any {
    this.baseUrl = `${environment.apiUrl}/${url}`;
  }

  protected buildUrl(url: string): string {
    return `${this.baseUrl}${url}`;
  }

  get(url: string): any;
  get<T>(url: string): any {
    return this.http
      .get<T>(`${this.baseUrl}${url}`)
      .pipe(catchError(this.handleError));
  }

  delete(id): any {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  create(entity): any {
    return this.http
      .post(this.buildUrl(""), entity)
      .pipe(catchError(this.handleError));
  }

  update(id, entity): any {
    return this.http
      .put(this.buildUrl(`/${id}`), entity)
      .pipe(catchError(this.handleError));
  }

  updateBankAccount(dto: StoreACHInformationDTO): any {
    return this.http
      .put(this.buildUrl(`/StoreACHInformation`), dto)
      .pipe(catchError(this.handleError));
  }

  protected handleError(error: HttpErrorResponse): any {
    // console.log('1. -------------------------', error.status, error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //     `Backend returned code ${error.message}, ` +
      //     `body was: ${error.error}`);
      return throwError(error);
    }
    // return an observable with a user-facing error message
    // return throwError(
    //   'Something bad happened; please try again later.');
    return throwError("Something bad happened; please try again later.");
  }

  getUserRoles(): List[] {
    const list: List[] = [
      { Id: "Farmer", Value: "Farmer" },
      { Id: "Cashier", Value: "Cashier" },
    ];
    return list;
  }
}
