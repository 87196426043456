import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Pagination } from "src/app/core/dtos";
import { BaseService } from "src/app/core/services/base.service";
import { FarmerConfirmForgotPasswordDTO } from "src/app/dtos/farmerConfirmForgotPasswordDTO";
import { FarmerForgotPasswordDTO } from "src/app/dtos/farmerForgotPasswordDTO";
import { FarmerSessionDTO } from "src/app/dtos/farmerSessionDTO";
import { JwTockensDTO } from "src/app/dtos/jwtTockensDTO";
import { UserDTO } from "src/app/dtos/userDTO";
import { UserListDTO } from "src/app/dtos/userListDTO";
import { UserPasswordUpdateDTO } from "src/app/dtos/userPasswordUpdateDTO";
import { UserUpdateDTO } from "src/app/dtos/userUpdateDTO";
import { FarmerUpdatePasswordDTO } from "src/app/dtos/farmerUpdatePasswordDTO";

@Injectable({
  providedIn: "root",
})
export class UsersService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("UserManagement");
  }

  getAll(
    filter: string,
    active: string,
    role: string,
    pageNumber: number = 1,
    sortColumn: string = "lastName",
    sortDirection: number = 1
  ): Observable<Pagination<any>> {
    // <UserListDTO>> {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter);
    }
    if (active) {
      params = params.append("active", active);
    }
    if (role) {
      params = params.append("role", role);
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    params = params.append("page", pageNumber.toString());

    return this.http.get<Pagination<UserListDTO>>(this.buildUrl("/List"), {
      params,
    });
  }

  resendInvitation(id: number): Observable<any> {
    return this.http.post(this.buildUrl(`/${id}/resendInvitation`), null);
  }

  getIsVerified(id: number): Observable<any> {
    return this.http.get<any>(this.buildUrl(`/${id}/isVerified`));
  }

  activate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Activate`), {});
  }

  deactivate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Deactivate`), {});
  }

  getProfile() {
    return this.http.get<UserDTO>(this.buildUrl(`/GetCurrentProfile`), {});
  }

  updateProfile(entity: UserUpdateDTO): Observable<any> {
    return this.http.put(this.buildUrl(`/UpdateCurrentProfile`), entity);
  }

  changePassword(dto: UserPasswordUpdateDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/change-pin`), dto);
  }

  getTokens(code: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/GetUserJwtTokens/${code}`)
    );
  }
  refreshTokens(refreshToken: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/RefreshUserJwtTokens/${refreshToken}`)
    );
  }

  login(dto: any): Observable<FarmerSessionDTO> {
    return this.http.post<FarmerSessionDTO>(this.buildUrl(`/Login`), dto);
  }

  forgotPassword(dto: FarmerForgotPasswordDTO): Observable<FarmerSessionDTO> {
    return this.http.post<FarmerSessionDTO>(
      this.buildUrl(`/ForgotPassword`),
      dto
    );
  }

  confirmForgotPassword(
    dto: FarmerConfirmForgotPasswordDTO
  ): Observable<FarmerSessionDTO> {
    return this.http.post<FarmerSessionDTO>(
      this.buildUrl(`/ConfirmForgotPassword`),
      dto
    );
  }

  updatePassword(dto: FarmerUpdatePasswordDTO): Observable<FarmerSessionDTO> {
    return this.http.post<FarmerSessionDTO>(
      this.buildUrl(`/UpdatePassword`),
      dto
    );
  }
}
