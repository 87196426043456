const Home = {
  text: "Home",
  link: "/home",
  icon: "bi bi-house-door",
  roles: ["Farmer"],
};

const Users = {
  text: "Users",
  link: "/users",
  icon: "bi bi-person",
  roles: ["Farmer"],
};

const Transactions = {
  text: "Transactions",
  link: "/transactions",
  icon: "bi bi-coin",
  roles: ["Farmer"],
};

export const menu = [Home, Users, Transactions];
