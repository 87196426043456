import { SettingsService } from '../settings/settings.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService) { }


    loginCognito(): any {
        const urlCognito =
            environment.cognitoUrl + 'login?' +
            'client_id=' + environment.cognitoClientId + '&' +
            'response_type=' + environment.cognitoResponseType + '&' +
            //'scope='+environment.cognitoScope+'&'+
            'redirect_uri=' + environment.cognitoRedirectUrl

            ;
        window.location.href = urlCognito;
    }

    getTokens(code): any {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Basic ' + btoa(environment.cognitoClientId + ':' + environment.cognitoClientSecret));

        let params = new HttpParams()
            .set('client_id', environment.cognitoClientId)
            .set('grant_type', 'authorization_code')
            .set('code', code)
            .set('redirect_uri', environment.cognitoRedirectUrl);

        return this.http.post(environment.cognitoUrl + 'oauth2/token', params, { headers: headers });
    }

    refreshToken(refreshToken): Observable<any> {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Basic ' + btoa(environment.cognitoClientId + ':' + environment.cognitoClientSecret));

        let params = new HttpParams()
            .set('client_id', environment.cognitoClientId)
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken)

        return this.http.post(environment.cognitoUrl + 'oauth2/token', params, { headers: headers });
    }

    logout(): any {
        this.clearStorage();
        const urlCognito =
            environment.cognitoUrl + 'logout?' +
            'response_type=' + environment.cognitoResponseType + '&' +
            'client_id=' + environment.cognitoClientId + '&' +
            'redirect_uri=' + environment.cognitoRedirectUrl + '&'
            ;
        window.location.href = urlCognito;
    }
    clearStorage(): any {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('issuingEntity');
    }

    get isAuthenticated(): any {
        return localStorage.getItem('currentUser') != null;
    }


    currentUser(): User {
        try {
            const transformed = plainToClass(User, JSON.parse(localStorage.getItem('currentUser')));

            if (transformed) {
                if (Array.isArray(transformed)) {
                    return transformed[0];
                }
                return transformed;
            }

            return null;
        } catch (error) { return null; }
    }

    setCurrentUser(user: User): any {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    changePassword(oldPassword: string, newPassword: string): Observable<any> { // ChangePasswordResultDto> {
        return this.http.post<any>(`${environment.apiUrl}/authentication/change-pin`, { oldPassword, newPassword });
        // return this.http.post<ChangePasswordResultDto>(`${environment.apiUrl}/authentication/change-password`, { oldPassword, newPassword });
    }
}

