import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Pagination } from "src/app/core/dtos";
import { BaseService } from "src/app/core/services/base.service";
import { StoreAddMarketDTO } from "src/app/dtos/storeAddMarketDTO";
import { StoreDTO } from "src/app/dtos/storeDTO";
import { StoreRemoveMarketDTO } from "src/app/dtos/storeRemoveMarketDTO";

@Injectable({
  providedIn: "root",
})
export class StoreService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("Store");
  }

  // getAll(
  //   name: string,
  //   vendorCode: string,
  //   active: string,
  //   pageNumber: number = 1,
  //   sortColumn: string = "name",
  //   sortDirection: number = 1
  // ): Observable<Pagination<StoreDTO>> {
  //   let params = new HttpParams();
  //   if (name) {
  //     params = params.append("filter", name);
  //   }
  //   if (vendorCode) {
  //     params = params.append("vendorCode", vendorCode);
  //   }
  //   if (active) {
  //     params = params.append("active", active);
  //   }
  //   if (sortColumn && sortDirection) {
  //     params = params.append("sortColumn", sortColumn);
  //     params = params.append("sortDirection", sortDirection.toString());
  //   }
  //   params = params.append("page", pageNumber.toString());

  //   return this.http.get<Pagination<StoreDTO>>(this.buildUrl(""), { params });
  // }

  // activate(id: number): Observable<StoreDTO> {
  //   return this.http.put<StoreDTO>(this.buildUrl(`/${id}/Activate`), {});
  // }

  // deactivate(id: number): Observable<StoreDTO> {
  //   return this.http.put<StoreDTO>(this.buildUrl(`/${id}/Deactivate`), {});
  // }

  addMarket(id: number, dto: StoreAddMarketDTO): Observable<StoreDTO> {
    return this.http.put<StoreDTO>(this.buildUrl(`/AddMarket/${id}`), dto);
  }

  removeMarket(id: number, dto: StoreRemoveMarketDTO): Observable<StoreDTO> {
    return this.http.put<StoreDTO>(this.buildUrl(`/RemoveMarket/${id}`), dto);
  }
}
