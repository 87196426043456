<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <!-- <p class="title">Sign Up</p> -->

      <div class="card-body" style="padding-top: 2em !important">
        <form [formGroup]="signUpForm" class="form-validate mb-3">
          <div class="row mb-3">
            <div class="col-md-8">
              <span class="form-inline">
                <label class="mr-5 text-info lead">{{
                  "selectLang" | translate
                }}</label>
                <select
                  class="form-control"
                  #selectedLang
                  (change)="switchLang(selectedLang.value)"
                >
                  <option
                    *ngFor="let language of translate.getLangs()"
                    [value]="language"
                    [selected]="language === translate.currentLang"
                  >
                    {{ language == "en" ? "English" : "Español" }}
                  </option>
                </select>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "firstName" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['firstName'].hasError('required') &&
                    (signUpForm.controls['firstName'].dirty ||
                      signUpForm.controls['firstName'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['firstName'].hasError('maxlength') &&
                    (signUpForm.controls['firstName'].dirty ||
                      signUpForm.controls['firstName'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.firstName }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "lastName" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['lastName'].hasError('required') &&
                    (signUpForm.controls['lastName'].dirty ||
                      signUpForm.controls['lastName'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['lastName'].hasError('maxlength') &&
                    (signUpForm.controls['lastName'].dirty ||
                      signUpForm.controls['lastName'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.lastName }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "emailAddress" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    #emailInput
                    class="form-control"
                    formControlName="emailAddress"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['emailAddress'].hasError('required') &&
                    (signUpForm.controls['emailAddress'].dirty ||
                      signUpForm.controls['emailAddress'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['emailAddress'].hasError('pattern') &&
                    (signUpForm.controls['emailAddress'].dirty ||
                      signUpForm.controls['emailAddress'].touched)
                  "
                >
                  {{ "validEmailMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['emailAddress'].hasError('maxlength') &&
                    (signUpForm.controls['emailAddress'].dirty ||
                      signUpForm.controls['emailAddress'].touched)
                  "
                >
                  {{ "256LengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.emailAddress }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ "phoneNumber" | translate }}</label>
                <div class="input-group with-focus">
                  <ngx-intl-tel-input
                    [cssClass]="'custom'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedStates"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National"
                    name="phoneNumber"
                    formControlName="phoneNumber"
                    (change)="formatPhone($event)"
                  >
                  </ngx-intl-tel-input>
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['phoneNumber'].hasError('required') &&
                    signUpForm.controls['phoneNumber'].dirty &&
                    signUpForm.controls['phoneNumber'].touched
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['phoneNumber'].invalid &&
                    signUpForm.controls['phoneNumber'].dirty &&
                    signUpForm.controls['phoneNumber'].touched
                  "
                >
                  {{ "invalidPhone" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.phoneNumber }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-12">
              <p class="lead bb text-info">{{ "farmInfo" | translate }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "farmCode" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    (keypress)="numberOnly($event)"
                    maxlength="4"
                    class="form-control"
                    formControlName="vendorCode"
                    required=""
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['vendorCode'].hasError('required') &&
                    (signUpForm.controls['vendorCode'].dirty ||
                      signUpForm.controls['vendorCode'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    (signUpForm.controls['vendorCode'].hasError('min') ||
                      signUpForm.controls['vendorCode'].hasError('max') ||
                      signUpForm.controls['vendorCode'].hasError('maxlength') ||
                      signUpForm.controls['vendorCode'].hasError(
                        'minlength'
                      )) &&
                    (signUpForm.controls['vendorCode'].dirty ||
                      signUpForm.controls['vendorCode'].touched)
                  "
                >
                  {{ "numericFieldMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.vendorCode }}
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "farmName" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    maxlength="100"
                    class="form-control"
                    formControlName="name"
                    required=""
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['name'].hasError('required') &&
                    (signUpForm.controls['name'].dirty ||
                      signUpForm.controls['name'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['name'].hasError('maxlength') &&
                    (signUpForm.controls['name'].dirty ||
                      signUpForm.controls['name'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">{{ errors?.name }}</div>
              </div>
            </div>
            <div class="col-md-4">
              <label class="required">Program</label>
              <ngx-select
                [multiple]="false"
                optionValueField="Id"
                optionTextField="Value"
                [items]="programItems"
                placeholder="Select..."
                [autoClearSearch]="true"
                formControlName="programCategory"
              >
              </ngx-select>
              <div
                class="text-danger ml-2 mt-2"
                *ngIf="
                  signUpForm.controls['programCategory'].hasError('required') &&
                  (signUpForm.controls['programCategory'].dirty ||
                    signUpForm.controls['programCategory'].touched)
                "
              >
                This field is required
              </div>
              <div class="text-danger ml-2 mt-2">
                {{ errors?.programCategory }}
              </div>
            </div>
          </div>
          <hr />
          <label class="certify-description">{{
            "validAdressMsg" | translate
          }}</label>
          <div class="row mb-2">
            <div class="col-md-12">
              <p class="lead bb text-info">
                {{ "mailingAddress" | translate }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "street" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    maxlength="100"
                    class="form-control"
                    formControlName="street"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['street'].hasError('required') &&
                    (signUpForm.controls['street'].dirty ||
                      signUpForm.controls['street'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['street'].hasError('maxlength') &&
                    (signUpForm.controls['street'].dirty ||
                      signUpForm.controls['street'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.street }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "city" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    maxlength="100"
                    class="form-control"
                    formControlName="city"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['city'].hasError('required') &&
                    (signUpForm.controls['city'].dirty ||
                      signUpForm.controls['city'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['city'].hasError('maxlength') &&
                    (signUpForm.controls['city'].dirty ||
                      signUpForm.controls['city'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.city }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "country" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    [typeahead]="dataSourceCounty"
                    (typeaheadLoading)="
                      changeTypeaheadLoading($event, 'county')
                    "
                    (typeaheadNoResults)="
                      changeTypeaheadNoResults($event, 'county')
                    "
                    (typeaheadOnSelect)="typeaheadOnSelect($event, 'county')"
                    [typeaheadOptionField]="'name'"
                    [typeaheadWaitMs]="500"
                    class="form-control"
                    [typeaheadMinLength]="0"
                    formControlName="countyName"
                    placeholder="Name..."
                  />
                  <div *ngIf="typeaheadCountyLoading === true">
                    <i class="fas fa-sync"></i>
                  </div>
                </div>
                <div *ngIf="typeaheadCountyNoResults === true" class="">
                  <i class="fa fa-times"></i> {{ "noResultMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['countyName'].hasError('required') &&
                    (signUpForm.controls['countyName'].dirty ||
                      signUpForm.controls['countyName'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['countyName'].hasError('maxlength') &&
                    (signUpForm.controls['countyName'].dirty ||
                      signUpForm.controls['countyName'].touched)
                  "
                >
                  {{ "2CharLengthMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['county'].hasError('required') &&
                    (signUpForm.controls['countyName'].dirty ||
                      signUpForm.controls['countyName'].touched)
                  "
                >
                  {{ "selectValue" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.countyName }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "state" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    [typeahead]="dataSourceState"
                    (typeaheadLoading)="changeTypeaheadLoading($event, 'state')"
                    (typeaheadNoResults)="
                      changeTypeaheadNoResults($event, 'state')
                    "
                    (typeaheadOnSelect)="typeaheadOnSelect($event, 'state')"
                    [typeaheadOptionField]="'name'"
                    [typeaheadWaitMs]="500"
                    class="form-control"
                    [typeaheadMinLength]="0"
                    formControlName="stateName"
                    placeholder="Name..."
                    readonly
                  />
                  <div *ngIf="typeaheadMailingStateLoading === true">
                    <i class="fas fa-sync"></i>
                  </div>
                </div>
                <div *ngIf="typeaheadMailingStateNoResults === true" class="">
                  <i class="fa fa-times"></i> {{ "noResultMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['stateName'].hasError('required') &&
                    (signUpForm.controls['stateName'].dirty ||
                      signUpForm.controls['stateName'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['stateName'].hasError('maxlength') &&
                    (signUpForm.controls['stateName'].dirty ||
                      signUpForm.controls['stateName'].touched)
                  "
                >
                  {{ "100CharLengthMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['state'].hasError('required') &&
                    (signUpForm.controls['stateName'].dirty ||
                      signUpForm.controls['stateName'].touched)
                  "
                >
                  {{ "selectValue" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.stateName }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="required">{{ "zipCode" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    maxlength="10"
                    class="form-control"
                    formControlName="zipCode"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['zipCode'].hasError('required') &&
                    (signUpForm.controls['zipCode'].dirty ||
                      signUpForm.controls['zipCode'].touched)
                  "
                >
                  {{ "fieldRedMsg" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['zipCode'].hasError('pattern') &&
                    (signUpForm.controls['zipCode'].dirty ||
                      signUpForm.controls['zipCode'].touched)
                  "
                >
                  {{ "zipFormat" | translate }}
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['zipCode'].hasError('maxlength') &&
                    (signUpForm.controls['zipCode'].dirty ||
                      signUpForm.controls['zipCode'].touched)
                  "
                >
                  {{ "10CharLengthMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.zipCode }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <p class="lead bb text-info">{{ "market" | translate }}</p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="card card-default">
                <div class="card-body">
                  <div class="row mt-1 mb-1">
                    <div class="text-danger ml-2 mt-2">
                      {{ errors?.relationType }}
                    </div>
                  </div>
                  <div class="row mb-3 ml-3">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="mr-2 required"
                          ><strong>{{ "market" | translate }}</strong></label
                        >
                        <input
                          autocomplete="nope"
                          [(ngModel)]="marketSearch"
                          [typeahead]="dataSourceMarket"
                          (typeaheadLoading)="
                            changeTypeaheadLoading($event, 'market')
                          "
                          (typeaheadNoResults)="
                            changeTypeaheadNoResults($event, 'market')
                          "
                          (typeaheadOnSelect)="
                            typeaheadOnSelect($event, 'market')
                          "
                          [typeaheadOptionField]="'description'"
                          [typeaheadWaitMs]="500"
                          placeholder="Name, Code..."
                          class="form-control"
                          [typeaheadMinLength]="0"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <div
                          class="text-danger ml-2 mt-2"
                          *ngIf="!selectedMarketControl"
                        >
                          {{ "fieldRedMsg" | translate }}
                        </div>
                        <div class="text-danger ml-2 mt-2">
                          {{ errors?.marketId }}
                        </div>
                        <div *ngIf="typeaheadMarketLoading === true">
                          <i class="fas fa-sync"></i>
                        </div>
                        <div *ngIf="typeaheadMarketNoResults === true" class="">
                          <i class="fa fa-times"></i>
                          {{ "noResultMsg" | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="mr-2"
                          ><strong>{{
                            "relationType" | translate
                          }}</strong></label
                        >
                        <div class="row">
                          <div class="col-md-10">
                            <ngx-select
                              [multiple]="false"
                              optionValueField="Id"
                              optionTextField="Value"
                              [items]="relationTypeItems"
                              [(ngModel)]="marketRelationType"
                              [ngModelOptions]="{ standalone: true }"
                              placeholder="All"
                              [autoClearSearch]="true"
                            ></ngx-select>
                          </div>
                          <div class="col-md-2">
                            <button
                              [disabled]="
                                marketSearchId === null ||
                                marketRelationType === null
                                  ? true
                                  : false
                              "
                              class="btn btn-sm btn-outline-success"
                              (click)="addRow()"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tbody>
                        <tr *ngFor="let item of selectedMarkets">
                          <td>{{ item?.marketName }}</td>
                          <td>
                            <div
                              *ngIf="item?.relationType === 'PRIMARY'"
                              class="badge badge-success"
                            >
                              Primary
                            </div>
                            <div
                              *ngIf="item?.relationType === 'SECONDARY'"
                              class="badge badge-warning"
                            >
                              Secondary
                            </div>
                          </td>
                          <td width="10%" class="text-center">
                            <button
                              type="button"
                              class="btn-xs btn btn-outline-primary"
                              title="Delete Market"
                              (click)="deactivateMarket(item?.marketId)"
                            >
                              <em class="fas fa-trash-alt"></em>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <label class="certify-description">
            {{ "disclaimer" | translate }}
          </label>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ "other" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    autocomplete="nope"
                    type="text"
                    maxlength="100"
                    class="form-control"
                    formControlName="products"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="clearfix mt-2">
            <div class="">
              <a class="forgot-pin" [routerLink]="'/auth/login'">{{
                "backLogin" | translate
              }}</a>
            </div>
          </div>
          <button
            class="btn btn-block btn-primary mt-2"
            type="submit"
            [disabled]="
              !signUpForm.valid ||
              !signUpForm.dirty ||
              selectedMarkets.length == 0
            "
            (click)="submit()"
          >
            {{ "signUpBtn" | translate }}
          </button>
        </form>
      </div>
      <div class="card-footer text-center">
        <label> {{ "label7" | translate }} @2009-2020 </label>
      </div>
    </div>
  </div>
</div>
