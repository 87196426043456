import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
// import Amplify, { Auth } from "aws-amplify";
import { LoaderService } from "src/app/core/loading/loader.service";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { FarmerConfirmForgotPasswordDTO } from "src/app/dtos/farmerConfirmForgotPasswordDTO";
import { environment } from "src/environments/environment";
import { UsersService } from "../../users/users.service";

@Component({
  selector: "app-recover",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.scss"],
})
export class RecoverComponent implements OnInit {
  recoverForm: FormGroup;
  password: string = "";
  confirmPassword: string = "";
  sameDigits: boolean = false;
  user: string;
  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private notifications: NotificationsService,
    private readonly route: ActivatedRoute,
    private loaderService: LoaderService,
    private userService: UsersService
  ) {
    this.route.params.subscribe((params) => {
      if (params.user) {
        this.user = params.user;
      }
    });
    // Amplify.configure({
    //   aws_cognito_region: environment.cognitoRegion,
    //   aws_user_pools_id: environment.cognitoUserPoolId,
    //   aws_user_pools_web_client_id: environment.cognitoClientId,
    //   Analytics: {
    //     disabled: true,
    //   },
    // });
  }

  ngOnInit() {
    this.recoverForm = this.fb.group({
      code: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      password: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      confirmPassword: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  submit() {
    this.loaderService.isLoading.next(true);
    const confirmForgotDto: FarmerConfirmForgotPasswordDTO = {
      email: this.user,
      confirmationCode: this.formControls.code.value,
      newPassword: this.formControls.password.value,
    };
    this.userService.confirmForgotPassword(confirmForgotDto).subscribe(
      (data) => {
        this.loaderService.isLoading.next(false);
        this.notifications.success(
          "Reset Password",
          "Reset password successfully"
        );
        this.router.navigate([`/auth/login`]);
      },
      (error) => {
        this.loaderService.isLoading.next(false);
        this.notifications.error("Error", "Password could not be reset");
      }
    );
  }
  public get formControls(): any {
    return this.recoverForm.controls;
  }
  validateSameFourDigits() {
    if (this.confirmPassword.length === 4) {
      const characters = this.confirmPassword.split("");
      let same = true;
      for (let index = 0; index < characters.length - 1 && same; index++) {
        if (characters[index] !== characters[index + 1]) {
          same = false;
        }
      }
      this.sameDigits = same;
    }
  }

  validatePassword() {
    if (
      this.formControls.password.touched &&
      this.formControls.confirmPassword.touched
    ) {
      if (
        this.formControls.password.value ===
        this.formControls.confirmPassword.value
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public noWhitespace(form: FormGroup) {
    let isWhitespace =
      form.controls.newPassword.value.trim().length === 0 &&
      form.controls.confirmNewPassword.value.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid;
  }
}
