<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <p class="title">Please create a new password for your account:</p>

      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="recoverForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0">Code</label>

            <div class="input-group with-focus">
              <input
                class="form-control password"
                type="password"
                name="code"
                maxlength="6"
                formControlName="code"
                required=""
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['code'].hasError('required') &&
                (recoverForm.controls['code'].dirty ||
                  recoverForm.controls['code'].touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="form-group">
            <label class="m-0">New Password</label>
            <div class="input-group with-focus">
              <input
                [(ngModel)]="password"
                class="form-control password"
                type="password"
                name="password"
                maxlength="30"
                formControlName="password"
                required=""
              />
            </div>
            <!-- <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['password'].hasError('required') &&
                (recoverForm.controls['password'].dirty ||
                  recoverForm.controls['password'].touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['password'].hasError('minLength') &&
                (recoverForm.controls['password'].dirty ||
                  recoverForm.controls['password'].touched)
              "
            >
              Passwords require at least 6 characters
            </div> -->
          </div>
          <div class="form-group">
            <label class="m-0">Confirm New Password</label>
            <div class="input-group with-focus">
              <input
                [(ngModel)]="confirmPassword"
                class="form-control password"
                type="password"
                name="confirmPassword"
                maxlength="30"
                formControlName="confirmPassword"
              />
            </div>
            <!-- <div
              class="text-danger"
              *ngIf="
                recoverForm.controls['confirmPassword'].hasError('required') &&
                recoverForm.controls['confirmPassword'].dirty
              "
            >
              This field is required
            </div> -->
            <div
              *ngIf="
                recoverForm.controls['password'].dirty ||
                recoverForm.controls['confirmPassword'].dirty
              "
            >
              <div>
                <div
                  [ngClass]="[
                    recoverForm.controls['password'].value.match(
                      '(.*[0-9].*)'
                    ) ||
                    recoverForm.controls['confirmNewPassword'].value.match(
                      '(.*[0-9].*)'
                    )
                      ? 'passwordCheck-valid-customizable'
                      : 'passwordCheck-notValid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      !(
                        recoverForm.controls['password'].value.match(
                          '(.*[0-9].*)'
                        ) ||
                        recoverForm.controls['confirmPassword'].value.match(
                          '(.*[0-9].*)'
                        )
                      )
                    "
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      recoverForm.controls['password'].value.match(
                        '(.*[0-9].*)'
                      ) ||
                      recoverForm.controls['confirmPassword'].value.match(
                        '(.*[0-9].*)'
                      )
                    "
                    >✓</span
                  >
                  <span class="checkPasswordText-numbers"
                    >Password must contain a number</span
                  >
                </div>
                <div
                  [ngClass]="[
                    recoverForm.controls['password'].value.match('.{6,}') ||
                    recoverForm.controls['confirmPassword'].value.match('.{6,}')
                      ? 'passwordCheck-valid-customizable'
                      : 'passwordCheck-notValid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      !(
                        recoverForm.controls['password'].value.match('.{6,}') ||
                        recoverForm.controls['confirmPassword'].value.match(
                          '.{6,}'
                        )
                      )
                    "
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      recoverForm.controls['password'].value.match('.{6,}') ||
                      recoverForm.controls['confirmPassword'].value.match(
                        '.{8,}'
                      )
                    "
                    >✓</span
                  >
                  <span class="checkPasswordText-length"
                    >Password must contain at least 6 characters</span
                  >
                </div>
                <div
                  [ngClass]="[
                    !validatePassword()
                      ? 'passwordCheck-notValid-customizable'
                      : 'passwordCheck-valid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="!validatePassword()"
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="validatePassword()"
                    >✓</span
                  >
                  <span class="checkPasswordText-matches"
                    >Passwords must match</span
                  >
                </div>

                <div
                  [ngClass]="[
                    !noWhitespace(recoverForm)
                      ? 'passwordCheck-notValid-customizable'
                      : 'passwordCheck-valid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="!noWhitespace(recoverForm)"
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="noWhitespace(recoverForm)"
                    >✓</span
                  >
                  <span class="checkPasswordText-valid-spaces"
                    >Password must not contain a leading or trailing space</span
                  >
                </div>
              </div>
            </div>
            <!-- <div *ngIf="!validatePassword()" class="text-danger">
              Passwords do not match
            </div> -->
          </div>
          <div class="clearfix mt-2">
            <div class="">
              <a class="forgot-password" [routerLink]="'/auth/login'"
                >Back Login</a
              >
            </div>
          </div>
          <button
            class="btn btn-block btn-primary mt-2"
            type="submit"
            [disabled]="
              !recoverForm.valid || !recoverForm.dirty || !validatePassword()
            "
            (click)="submit()"
          >
            Recover Password
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
