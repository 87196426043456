<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <p class="title">Please change your password:</p>
      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="changeForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0">Email</label>
            <div class="input-group with-focus">
              <input
                class="form-control email"
                type="email"
                name="email"
                formControlName="email"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <label class="m-0">New Password</label>
            <div class="input-group with-focus">
              <input
                [(ngModel)]="newPassword"
                class="form-control"
                type="password"
                name="newPassword"
                maxlength="30"
                formControlName="newPassword"
                required
              />
            </div>
            <!-- <div
              class="text-danger"
              *ngIf="
                changeForm.controls['newPassword'].hasError('required') &&
                (changeForm.controls['newPassword'].dirty ||
                  changeForm.controls['newPassword'].touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                changeForm.controls['newPassword'].hasError('minLength') &&
                (changeForm.controls['newPassword'].dirty ||
                  changeForm.controls['newPassword'].touched)
              "
            >
              Passwords require at least 6 characters
            </div> -->
          </div>
          <div class="form-group">
            <label class="m-0">Confirm New Password</label>
            <div class="input-group with-focus">
              <input
                [(ngModel)]="confirmNewPassword"
                class="form-control"
                type="password"
                name="confirmNewPassword"
                maxlength="30"
                formControlName="confirmNewPassword"
                required
              />
            </div>
            <!-- <div
              class="text-danger"
              *ngIf="
                changeForm.controls['confirmNewPassword'].hasError(
                  'required'
                ) &&
                (changeForm.controls['confirmNewPassword'].dirty ||
                  changeForm.controls['confirmNewPassword'].touched)
              "
            >
              This field is required
            </div> -->
            <div
              *ngIf="
                changeForm.controls['newPassword'].dirty ||
                changeForm.controls['confirmNewPassword'].dirty
              "
            >
              <div>
                <div
                  [ngClass]="[
                    changeForm.controls['newPassword'].value.match(
                      '(.*[0-9].*)'
                    ) ||
                    changeForm.controls['confirmNewPassword'].value.match(
                      '(.*[0-9].*)'
                    )
                      ? 'passwordCheck-valid-customizable'
                      : 'passwordCheck-notValid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      !(
                        changeForm.controls['newPassword'].value.match(
                          '(.*[0-9].*)'
                        ) ||
                        changeForm.controls['confirmNewPassword'].value.match(
                          '(.*[0-9].*)'
                        )
                      )
                    "
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      changeForm.controls['newPassword'].value.match(
                        '(.*[0-9].*)'
                      ) ||
                      changeForm.controls['confirmNewPassword'].value.match(
                        '(.*[0-9].*)'
                      )
                    "
                    >✓</span
                  >
                  <span class="checkPasswordText-numbers"
                    >Password must contain a number</span
                  >
                </div>
                <div
                  [ngClass]="[
                    changeForm.controls['newPassword'].value.match('.{6,}') ||
                    changeForm.controls['confirmNewPassword'].value.match(
                      '.{8,}'
                    )
                      ? 'passwordCheck-valid-customizable'
                      : 'passwordCheck-notValid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      !(
                        changeForm.controls['newPassword'].value.match(
                          '.{6,}'
                        ) ||
                        changeForm.controls['confirmNewPassword'].value.match(
                          '.{6,}'
                        )
                      )
                    "
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="
                      changeForm.controls['newPassword'].value.match('.{6,}') ||
                      changeForm.controls['confirmNewPassword'].value.match(
                        '.{8,}'
                      )
                    "
                    >✓</span
                  >
                  <span class="checkPasswordText-length"
                    >Password must contain at least 6 characters</span
                  >
                </div>
                <div
                  [ngClass]="[
                    !passwordsMatch(changeForm)
                      ? 'passwordCheck-notValid-customizable'
                      : 'passwordCheck-valid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="!passwordsMatch(changeForm)"
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="passwordsMatch(changeForm)"
                    >✓</span
                  >
                  <span class="checkPasswordText-matches"
                    >Passwords must match</span
                  >
                </div>

                <div
                  [ngClass]="[
                    !noWhitespace(changeForm)
                      ? 'passwordCheck-notValid-customizable'
                      : 'passwordCheck-valid-customizable'
                  ]"
                >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="!noWhitespace(changeForm)"
                    >✖</span
                  >
                  <span
                    aria-hidden="true"
                    class="check-numbers"
                    *ngIf="noWhitespace(changeForm)"
                    >✓</span
                  >
                  <span class="checkPasswordText-valid-spaces"
                    >Password must not contain a leading or trailing space</span
                  >
                </div>
              </div>
            </div>
            <!-- <div *ngIf="!passwordsMatch(changeForm)" class="text-danger">
              Passwords do not match
            </div> -->
          </div>
          <div class="clearfix mt-2">
            <div class="">
              <a class="forgot-change" [routerLink]="'/auth/login'"
                >Back Login</a
              >
            </div>
          </div>
          <button
            class="btn btn-block btn-primary mt-2"
            type="submit"
            [disabled]="
              !changeForm.valid ||
              !changeForm.dirty ||
              !passwordsMatch(changeForm)
            "
            (click)="submit()"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
