<div class="wrapper">
  <div class="block-center mt-4 wd-responsive">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-primary">
        <img
          class="solimarket"
          src="/assets/img/solimarket-portal.png"
          alt="image"
        />
      </div>
      <h1 class="title">Forgot your password?</h1>

      <div class="card-body" style="padding-top: 1em !important">
        <form [formGroup]="resetForm" class="form-validate mb-3">
          <div class="form-group">
            <label class="m-0 mb-1"
              >Enter your username below and we will send a message to reset
              your password</label
            >

            <div class="input-group with-focus">
              <input
                class="form-control"
                autocomplete="off"
                formControlName="userName"
                required=""
                placeholder="Username"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                resetForm.controls['userName'].hasError('required') &&
                (resetForm.controls['userName'].dirty ||
                  resetForm.controls['userName'].touched)
              "
            >
              This field is required
            </div>
            <div class="text-danger ml-2 mt-2">{{ errors?.userName }}</div>
          </div>
          <div class="clearfix mt-2">
            <div class="">
              <a class="forgot-pin" [routerLink]="'/auth/login'">Back Login</a>
            </div>
          </div>
          <button
            class="btn btn-block btn-primary mt-4"
            type="submit"
            [disabled]="!resetForm.valid || !resetForm.dirty || !validUser()"
            (click)="submit()"
          >
            Reset my password
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
