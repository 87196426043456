import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class LookupService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("");
  }

  searchBanks(filter: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    var result = this.http
      .get<LookupValueDTO[]>(this.buildUrl(`Bank/${filter}`), {
        params,
      })
      .pipe(catchError(this.handleError));
    return result;
  }

  searchRoutings(bank: string, filter: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (bank) {
      params = params.append("bank", bank);
    }
    if (filter) {
      params = params.append("filter", filter);
    }

    return this.http
      .get<LookupValueDTO[]>(this.buildUrl(`Bank/RoutingNumbers`), {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  lookupSearch(lookupType: string, searchCriteria: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (searchCriteria) {
      params = params.append("filter", searchCriteria);
    }

    return this.http
      .get<LookupValueDTO[]>(this.buildUrl(`Lookups/Search/${lookupType}`), {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  lookupSearchAll(lookupType: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");

    return this.http
      .get<LookupValueDTO[]>(this.buildUrl(`Lookups/Search/${lookupType}`), {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  searchMarkets(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();
    params = params.append("disableLoading", "true");
    if (filter) {
      params = params.append("filter", filter);
    }
    return this.http.get<LookupValueDTO[]>(this.buildUrl(`Market/LookUp`), {
      params,
    });
  }

  searchStates(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("STATE", filter);
  }

  searchCounties(filter: string): Observable<LookupValueDTO[]> {
    return this.lookupSearch("COUNTY", filter);
  }
}
